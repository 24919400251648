import { useContext, useState } from "react";

import {
	ListSubheader,
	alpha,
	Box,
	List,
	styled,
	Button,
	ListItem,
	Collapse,
} from "@mui/material";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";
import { isSuperAdmin } from "src/utils/UserRoleUtils";
// analytic
import ShoppingCartSharpIcon from "@mui/icons-material/ShoppingCartSharp";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import PieChartSharpIcon from "@mui/icons-material/PieChartSharp";
import LocalMallSharpIcon from "@mui/icons-material/LocalMallSharp";
import PeopleOutlineSharpIcon from "@mui/icons-material/PeopleOutlineSharp";
import PlaceSharpIcon from "@mui/icons-material/PlaceSharp";
import AccessibilitySharpIcon from "@mui/icons-material/AccessibilitySharp";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";

// Toko
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";
import LoyaltySharpIcon from "@mui/icons-material/LoyaltySharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
// Feature
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import AccessTimeFilledSharpIcon from "@mui/icons-material/AccessTimeFilledSharp";
import ArticleIcon from "@mui/icons-material/Article";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import InterestsIcon from "@mui/icons-material/Interests";
import SpaIcon from "@mui/icons-material/Spa";
import MosqueIcon from "@mui/icons-material/Mosque";
import GppGoodIcon from "@mui/icons-material/GppGood";

import PlaceIcon from "@mui/icons-material/Place";

import GroupsIcon from "@mui/icons-material/Groups";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";

import CampaignIcon from "@mui/icons-material/Campaign";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import SettingsIcon from "@mui/icons-material/Settings";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import DesignServicesTwoToneIcon from "@mui/icons-material/DesignServicesTwoTone";
import BrightnessLowTwoToneIcon from "@mui/icons-material/BrightnessLowTwoTone";
import MmsTwoToneIcon from "@mui/icons-material/MmsTwoTone";
import TableChartTwoToneIcon from "@mui/icons-material/TableChartTwoTone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import BallotTwoToneIcon from "@mui/icons-material/BallotTwoTone";
import BeachAccessTwoToneIcon from "@mui/icons-material/BeachAccessTwoTone";
import EmojiEventsTwoToneIcon from "@mui/icons-material/EmojiEventsTwoTone";
import FilterVintageTwoToneIcon from "@mui/icons-material/FilterVintageTwoTone";
import HowToVoteTwoToneIcon from "@mui/icons-material/HowToVoteTwoTone";
import LocalPharmacyTwoToneIcon from "@mui/icons-material/LocalPharmacyTwoTone";
import RedeemTwoToneIcon from "@mui/icons-material/RedeemTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import TrafficTwoToneIcon from "@mui/icons-material/TrafficTwoTone";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import ChromeReaderModeTwoToneIcon from "@mui/icons-material/ChromeReaderModeTwoTone";
import WorkspacePremiumTwoToneIcon from "@mui/icons-material/WorkspacePremiumTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import DisplaySettingsTwoToneIcon from "@mui/icons-material/DisplaySettingsTwoTone";
import GridViewIcon from "@mui/icons-material/GridView";

const MenuWrapper = styled(Box)(
	({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0.6)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(0.3)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: normal;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.black[50]};
      padding: ${theme.spacing(1, 0.625)};
      line-height: 0.2;
    }
`
);

const SubMenuWrapper = styled(Box)(
	({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: normal;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.secondary.dark};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(0.5, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.secondary.light};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.secondary.light};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.primary.main, 0.06)};
            color: ${theme.colors.primary.main};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.primary.main};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
									"transform",
									"opacity",
								])};
                width: 3px;
                height: 3px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
	const { closeSidebar } = useContext(SidebarContext);
	const [open, setOpen] = useState(false);
	const [openBroadcast, setOpenBroadcast] = useState(false);
	const [openProduct, setOpenProduct] = useState(false);
	const [openDiscount, setOpenDiscount] = useState(false);
	const [openStoreSettings, setOpenStoreSettings] = useState(false);
	const [openMember, setOpenMember] = useState(false);
	const [openActivity, setOpenActivity] = useState(false);
	const [openJournal, setOpenJournal] = useState(false);
	const [openArticle, setOpenArticle] = useState(false);
	const [openPosts, setOpenPosts] = useState(false);
	const [openPeriod, setOpenPeriod] = useState(false);
	const [openPrayer, setOpenPrayer] = useState(false);
	const [openArea, setOpenArea] = useState(false);
	const [openQuran, setOpenQuran] = useState(false);
	const [openAnalytics, setOpenAnalytics] = useState(false);

	const handleToggle = () => {
		setOpen(!open);
	};

	const closeSidebar1 = () => {
		setOpen(false);
	};

	const crypto_items = [
		{
			title: "Overview",
			icon: <GridViewIcon />,
			to: "/dashboards/crypto",
		},
		{
			title: "Overview",
			icon: <GridViewIcon />,
			to: "/dashboards/crypto",
		},
		{
			title: "Overview",
			icon: <GridViewIcon />,
			to: "/dashboards/crypto",
		},
	];

	const distributor_menus = [
		{
			title: "",
			items: [
				{
					title: "Dasboard",
					isCollapse: false,
					icon: <GridViewIcon />,
					to: "/dashboards",
				},
				{
					title: "Analytics",
					isCollapse: true,
					icon: <GridViewIcon />,
					onclick: () => setOpenAnalytics(!openAnalytics),
					open: openAnalytics,
					subitems: [
						{
							title: "Penjualan",
							icon: <ShoppingCartSharpIcon />,
							to: "/analytics/statistics",
						},
						{
							title: "Produk Terbaik",
							icon: <PeopleOutlineSharpIcon />,
							to: "/analytics/best-sellers",
						},
						{
							title: "Customer Terbaik",
							icon: <PeopleOutlineSharpIcon />,
							to: "/analytics/best-customers",
						},
						{
							title: "Lokasi Terbaik",
							icon: <PlaceSharpIcon />,
							to: "/analytics/best-locations",
						},
					],
				},
			],
		},
		{
			title: "Toko",
			items: [
				{
					title: "Order",
					isCollapse: false,
					icon: <ShoppingCartSharpIcon />,
					to: "/store/orders",
				},
				{
					title: "Produk",
					isCollapse: false,
					icon: <LocalMallSharpIcon />,
					to: "/store/products",
				},
			],
		},
	];

	const temp_menus = [
		{
			title: "",
			items: [
				{
					title: "Dasboard",
					isCollapse: false,
					icon: <GridViewIcon />,
					to: "/dashboards",
				},
			],
		},
		{
			title: "Analytics",
			items: [
				{
					title: "Report",
					icon: <ShoppingCartSharpIcon />,
					to: "/analytics/report",
				},
				{
					title: "Produk Terbaik",
					icon: <PeopleOutlineSharpIcon />,
					to: "/analytics/best-sellers",
				},
				{
					title: "Customer Terbaik",
					icon: <PeopleOutlineSharpIcon />,
					to: "/analytics/best-customers",
				},
				{
					title: "Lokasi Terbaik",
					isCollapse: false,
					icon: <PlaceSharpIcon />,
					to: "/analytics/best-locations",
				},
				{
					title: "PIC Terbaik",
					isCollapse: false,
					icon: <AccessibilitySharpIcon />,
					to: "/analytics/best-pic",
				},
				{
					title: "Affiliator",
					isCollapse: false,
					icon: <SensorOccupiedIcon />,
					to: "/analytics/affiliate",
				},
				{
					title: "Kritik & Saran",
					isCollapse: false,
					icon: <RateReviewOutlinedIcon />,
					to: "/analytics/user-feedback",
				},
				{
					title: "Moota",
					isCollapse: false,
					icon: <CreditScoreOutlinedIcon />,
					to: "/analytics/moota",
				},
			],
		},
		{
			title: "Toko",
			items: [
				{
					title: "Order",
					isCollapse: false,
					icon: <ShoppingCartSharpIcon />,
					to: "/store/orders",
				},
				{
					title: "Produk",
					isCollapse: true,
					icon: <LocalMallSharpIcon />,
					onclick: () => setOpenProduct(!openProduct),
					open: openProduct,
					subitems: [
						{
							title: "Kategori",
							icon: <GridViewIcon />,
							to: "/store/category",
						},
						{
							title: "Sub Kategori",
							icon: <GridViewIcon />,
							to: "/store/sub-category",
						},
						{
							title: "Produk",
							icon: <GridViewIcon />,
							to: "/store/products",
						},
					],
				},
				{
					title: "Promo",
					isCollapse: true,
					icon: <VerifiedSharpIcon />,
					onclick: () => setOpenDiscount(!openDiscount),
					open: openDiscount,
					subitems: [
						{
							title: "Potongan Harga",
							icon: <GridViewIcon />,
							to: "/store/discount",
						},
						{
							title: "Kupon",
							icon: <GridViewIcon />,
							to: "/store/coupon",
						},
						{
							title: "Flashsale",
							icon: <GridViewIcon />,
							to: "/store/flashsale",
						},
						{
							title: "Bundling",
							icon: <GridViewIcon />,
							to: "/store/bundling",
						},
						{
							title: "Grosir",
							icon: <GridViewIcon />,
							to: "/store/grosir",
						},
					],
				},
				{
					title: "PIC",
					isCollapse: false,
					icon: <AccessibilitySharpIcon />,
					to: "/store/pic",
				},
				{
					title: "Hadiah",
					isCollapse: false,
					icon: <LoyaltySharpIcon />,
					to: "/store/rewards",
				},
				{
					title: "Pengaturan Toko",
					isCollapse: true,
					icon: <SettingsSharpIcon />,
					onclick: () => setOpenStoreSettings(!openStoreSettings),
					open: openStoreSettings,
					subitems: [
						{
							title: "Banner",
							icon: <GridViewIcon />,
							to: "/store/settings/banner",
						},
						{
							title: "Popup",
							icon: <GridViewIcon />,
							to: "/store/settings/popup",
						},
						{
							title: "Customer Service",
							icon: <GridViewIcon />,
							to: "/store/settings/customer-service",
						},
						{
							title: "Kategori CS",
							icon: <GridViewIcon />,
							to: "/store/settings/cs-category",
						},
						{
							title: "Akun Bank",
							icon: <GridViewIcon />,
							to: "/store/settings/bank-account",
						},
						{
							title: "Kurir",
							icon: <GridViewIcon />,
							to: "/store/settings/courier",
						},
						{
							title: "User COD",
							icon: <GridViewIcon />,
							to: "/store/settings/user-cod",
						},
					],
				},
				{
					title: "FAQ",
					isCollapse: false,
					icon: <QuizOutlinedIcon />,
					to: "/store/faq",
				},
				{
					title: "Komplain Order",
					isCollapse: false,
					icon: <CrisisAlertOutlinedIcon />,
					to: "/store/order-complains",
				},
			],
		},
		{
			title: "Fitur",
			items: [
				{
					title: "Kelola Kegiatan",
					isCollapse: true,
					icon: <CalendarMonthSharpIcon />,
					onclick: () => setOpenActivity(!openActivity),
					open: openActivity,
					subitems: [
						{
							title: "Ulangan Aktivitas",
							icon: <GridViewIcon />,
							to: "/features/activity-repetition",
						},
						{
							title: "Kategori Kegiatan",
							icon: <GridViewIcon />,
							to: "/features/activity-categories",
						},
						{
							title: "Satuan Kegiatan",
							icon: <GridViewIcon />,
							to: "/features/activity-unit",
						},
						{
							title: "Kegiatan",
							icon: <GridViewIcon />,
							to: "/features/activity",
						},
					],
				},
				{
					title: "Jurnal Kegiatan",
					isCollapse: true,
					icon: <AccessTimeFilledSharpIcon />,
					onclick: () => setOpenJournal(!openJournal),
					open: openJournal,
					subitems: [
						{
							title: "Paket Jurnal",
							icon: <GridViewIcon />,
							to: "/features/journal-packages",
						},
						{
							title: "Jurnal",
							icon: <GridViewIcon />,
							to: "/features/journal",
						},
						{
							title: "Catatan",
							icon: <GridViewIcon />,
							to: "/features/journal-notes",
						},
					],
				},
				{
					title: "Share Artikel",
					isCollapse: true,
					icon: <ArticleIcon />,
					onclick: () => setOpenArticle(!openArticle),
					open: openArticle,
					subitems: [
						{
							title: "Kategori",
							icon: <GridViewIcon />,
							to: "/features/article-categories",
						},
						{
							title: "List Artikel",
							icon: <GridViewIcon />,
							to: "/features/article-list",
						},
					],
				},
				{
					title: "Share Video",
					isCollapse: false,
					icon: <VideoCameraBackIcon />,
					to: "/features/share-videos",
				},
				{
					title: "Al Quran",
					isCollapse: true,
					icon: <AutoStoriesIcon />,
					onclick: () => setOpenQuran(!openQuran),
					open: openQuran,
					subitems: [
						{
							title: "Juz",
							icon: <GridViewIcon />,
							to: "/features/quran-juz",
						},
						{
							title: "Surat",
							icon: <GridViewIcon />,
							to: "/features/quran-surah",
						},
						{
							title: "Ayat",
							icon: <GridViewIcon />,
							to: "/features/quran-verses",
						},
						{
							title: "Terahir Dibaca",
							icon: <GridViewIcon />,
							to: "/features/quran-last-read",
						},
						{
							title: "Favorit",
							icon: <GridViewIcon />,
							to: "/features/quran-favorite",
						},
					],
				},
				{
					title: "Manage Postingan",
					isCollapse: true,
					icon: <InterestsIcon />,
					onclick: () => setOpenPosts(!openPosts),
					open: openPosts,
					subitems: [
						{
							title: "Postingan",
							icon: <GridViewIcon />,
							to: "/features/posts",
						},
						{
							title: "Laporan Postingan",
							icon: <GridViewIcon />,
							to: "/features/post-reports",
						},
						{
							title: "Komentar Postingan",
							icon: <GridViewIcon />,
							to: "/features/post-comments",
						},
						{
							title: "Laporan Komentar",
							icon: <GridViewIcon />,
							to: "/features/post-comments-reports",
						},
					],
				},
				{
					title: "Manage Periode",
					isCollapse: true,
					icon: <SpaIcon />,
					onclick: () => setOpenPeriod(!openPeriod),
					open: openPeriod,
					subitems: [
						{
							title: "Pengaturan Haid",
							icon: <GridViewIcon />,
							to: "/features/periods-settings",
						},
						{
							title: "Haid",
							icon: <GridViewIcon />,
							to: "/features/periods",
						},
					],
				},
				{
					title: "Asmaul Husna",
					isCollapse: false,
					icon: <GppGoodIcon />,
					to: "/features/asmaul-husna",
				},
			],
		},
		{
			title: "Membership",
			items: [
				{
					title: "Member",
					isCollapse: true,
					icon: <GroupsIcon />,
					onclick: () => setOpenMember(!openMember),
					open: openMember,
					subitems: [
						{
							title: "Customer",
							icon: <PersonIcon />,
							to: "/membership/member/customers",
						},
						{
							title: "Reseller",
							icon: <GroupIcon />,
							to: "/membership/member/reseller",
						},
						{
							title: "Distributor",
							icon: <GroupIcon />,
							to: "/membership/member/distributors",
						},
						{
							title: "Reseller Gold",
							icon: <GroupAddIcon />,
							to: "/membership/member/reseller-gold",
						},
						{
							title: "Agen",
							icon: <GroupsIcon />,
							to: "/membership/member/agents",
						},
						{
							title: "Member Premium",
							icon: <GroupAddIcon />,
							to: "/membership/member/premium",
						},
						{
							title: "Distributor Plus",
							icon: <GroupAddIcon />,
							to: "/membership/member/ditributor-plus",
						},
						{
							title: "User Terhapus",
							icon: <GroupsIcon />,
							to: "/membership/member/deleted",
						},
					],
				},
			],
		},
	];

	const menus = [
		{
			title: "",
			items: [
				{
					title: "Dasboard",
					isCollapse: false,
					icon: <GridViewIcon />,
					to: "/dashboards",
				},
			],
		},
		{
			title: "Analytics",
			items: [
				{
					title: "Penjualan",
					isCollapse: false,
					icon: <ShoppingCartSharpIcon />,
					to: "/analytics/sale",
				},
				// {
				// 	title: "Statistik",
				// 	isCollapse: false,
				// 	icon: <PieChartSharpIcon />,
				// 	to: "/analytics/statistics",
				// },
				{
					title: "Produk Terbaik",
					isCollapse: false,
					icon: <LocalMallSharpIcon />,
					to: "/analytics/best-sellers",
				},
				{
					title: "Member Terbaik",
					isCollapse: false,
					icon: <PeopleOutlineSharpIcon />,
					to: "/analytics/best-members",
				},
				{
					title: "Customer Terbaik",
					icon: <PeopleOutlineSharpIcon />,
					to: "/analytics/best-customers",
				},
				{
					title: "Lokasi Terbaik",
					isCollapse: false,
					icon: <PlaceSharpIcon />,
					to: "/analytics/best-locations",
				},
				{
					title: "PIC Terbaik",
					isCollapse: false,
					icon: <AccessibilitySharpIcon />,
					to: "/analytics/best-pic",
				},
				{
					title: "Affiliator",
					isCollapse: false,
					icon: <SensorOccupiedIcon />,
					to: "/analytics/affiliate",
				},
				{
					title: "Kritik & Saran",
					isCollapse: false,
					icon: <RateReviewOutlinedIcon />,
					to: "/analytics/user-feedback",
				},
				{
					title: "Moota",
					isCollapse: false,
					icon: <CreditScoreOutlinedIcon />,
					to: "/analytics/moota",
				},
			],
		},
		{
			title: "Toko",
			items: [
				{
					title: "Order",
					isCollapse: false,
					icon: <ShoppingCartSharpIcon />,
					to: "/store/orders",
				},
				{
					title: "Produk",
					isCollapse: true,
					icon: <LocalMallSharpIcon />,
					onclick: () => setOpenProduct(!openProduct),
					open: openProduct,
					subitems: [
						{
							title: "Kategori",
							icon: <GridViewIcon />,
							to: "/store/category",
						},
						{
							title: "Sub Kategori",
							icon: <GridViewIcon />,
							to: "/store/sub-category",
						},
						{
							title: "Produk",
							icon: <GridViewIcon />,
							to: "/store/products",
						},
						{
							title: "Wishlist",
							icon: <GridViewIcon />,
							to: "/store/wishlist",
						},
						{
							title: "Hubungan",
							icon: <GridViewIcon />,
							to: "/store/connection",
						},
					],
				},
				{
					title: "Promo",
					isCollapse: true,
					icon: <VerifiedSharpIcon />,
					onclick: () => setOpenDiscount(!openDiscount),
					open: openDiscount,
					subitems: [
						{
							title: "Potongan Harga",
							icon: <GridViewIcon />,
							to: "/store/discount",
						},
						{
							title: "Kupon",
							icon: <GridViewIcon />,
							to: "/store/coupon",
						},
						{
							title: "Flashsale",
							icon: <GridViewIcon />,
							to: "/store/flashsale",
						},
						{
							title: "Bundling",
							icon: <GridViewIcon />,
							to: "/store/bundling",
						},
					],
				},
				{
					title: "Hadiah",
					isCollapse: false,
					icon: <LoyaltySharpIcon />,
					to: "/store/rewards",
				},
				{
					title: "Pengaturan Toko",
					isCollapse: false,
					icon: <SettingsSharpIcon />,
					to: "/store/settings",
				},
				{
					title: "PIC",
					isCollapse: false,
					icon: <AccessibilitySharpIcon />,
					to: "/store/pic",
				},
				{
					title: "Komplain Order",
					isCollapse: false,
					icon: <CrisisAlertOutlinedIcon />,
					to: "/store/order-complains",
				},
			],
		},
		{
			title: "Fitur",
			items: [
				{
					title: "Kelola Kegiatan",
					isCollapse: true,
					icon: <CalendarMonthSharpIcon />,
					onclick: () => setOpenActivity(!openActivity),
					open: openActivity,
					subitems: [
						{
							title: "Ulangan Aktivitas",
							icon: <GridViewIcon />,
							to: "/features/activity-repetition",
						},
						{
							title: "Kategori Kegiatan",
							icon: <GridViewIcon />,
							to: "/features/activity-categories",
						},
						{
							title: "Satuan Kegiatan",
							icon: <GridViewIcon />,
							to: "/features/activity-unit",
						},
						{
							title: "Kegiatan",
							icon: <GridViewIcon />,
							to: "/features/activity",
						},
					],
				},
				{
					title: "Jurnal Kegiatan",
					isCollapse: true,
					icon: <AccessTimeFilledSharpIcon />,
					onclick: () => setOpenJournal(!openJournal),
					open: openJournal,
					subitems: [
						{
							title: "Paket Jurnal",
							icon: <GridViewIcon />,
							to: "/features/journal-packages",
						},
						{
							title: "Jurnal",
							icon: <GridViewIcon />,
							to: "/features/journal",
						},
						{
							title: "Catatan",
							icon: <GridViewIcon />,
							to: "/features/journal-notes",
						},
					],
				},
				{
					title: "Share Artikel",
					isCollapse: true,
					icon: <ArticleIcon />,
					onclick: () => setOpenArticle(!openArticle),
					open: openArticle,
					subitems: [
						{
							title: "Kategori",
							icon: <GridViewIcon />,
							to: "/features/article-categories",
						},
						{
							title: "List Artikel",
							icon: <GridViewIcon />,
							to: "/features/article-list",
						},
					],
				},
				{
					title: "Share Video",
					isCollapse: false,
					icon: <VideoCameraBackIcon />,
					to: "/features/share-videos",
				},
				{
					title: "Al Quran",
					isCollapse: true,
					icon: <AutoStoriesIcon />,
					onclick: () => setOpenQuran(!openQuran),
					open: openQuran,
					subitems: [
						{
							title: "Juz",
							icon: <GridViewIcon />,
							to: "/features/quran-juz",
						},
						{
							title: "Surat",
							icon: <GridViewIcon />,
							to: "/features/quran-surah",
						},
						{
							title: "Ayat",
							icon: <GridViewIcon />,
							to: "/features/quran-verses",
						},
						{
							title: "Terahir Dibaca",
							icon: <GridViewIcon />,
							to: "/features/quran-last-read",
						},
						{
							title: "Favorit",
							icon: <GridViewIcon />,
							to: "/features/quran-favorite",
						},
					],
				},
				{
					title: "Manage Postingan",
					isCollapse: true,
					icon: <InterestsIcon />,
					onclick: () => setOpenPosts(!openPosts),
					open: openPosts,
					subitems: [
						{
							title: "Postingan",
							icon: <GridViewIcon />,
							to: "/features/posts",
						},
						{
							title: "Laporan Postingan",
							icon: <GridViewIcon />,
							to: "/features/post-reports",
						},
						{
							title: "Komentar Postingan",
							icon: <GridViewIcon />,
							to: "/features/post-comments",
						},
						{
							title: "Laporan Komentar",
							icon: <GridViewIcon />,
							to: "/features/post-comments-reports",
						},
					],
				},
				{
					title: "Manage Periode",
					isCollapse: true,
					icon: <SpaIcon />,
					onclick: () => setOpenPeriod(!openPeriod),
					open: openPeriod,
					subitems: [
						{
							title: "Pengaturan Haid",
							icon: <GridViewIcon />,
							to: "/features/periods-settings",
						},
						{
							title: "Haid",
							icon: <GridViewIcon />,
							to: "/features/periods",
						},
					],
				},
				{
					title: "Kelola Do`a",
					isCollapse: true,
					icon: <MosqueIcon />,
					onclick: () => setOpenPrayer(!openPrayer),
					open: openPrayer,
					subitems: [
						{
							title: "Kategori Do`a",
							icon: <GridViewIcon />,
							to: "/features/prayers-categories",
						},
						{
							title: "Do`a",
							icon: <GridViewIcon />,
							to: "/features/prayers",
						},
					],
				},
				{
					title: "Asmaul Husna",
					isCollapse: false,
					icon: <GppGoodIcon />,
					to: "/features/asmaul-husna",
				},
			],
		},
		{
			title: "Membership",
			items: [
				{
					title: "Member",
					isCollapse: true,
					icon: <GroupsIcon />,
					onclick: () => setOpenMember(!openMember),
					open: openMember,
					subitems: [
						// {
						// 	title: "Semua",
						// 	icon: <GroupsIcon />,
						// 	to: "/membership/member/all",
						// },
						{
							title: "Customer",
							icon: <PersonIcon />,
							to: "/membership/member/customers",
						},
						{
							title: "Reseller",
							icon: <GroupIcon />,
							to: "/membership/member/reseller",
						},
						{
							title: "Distributor",
							icon: <GroupIcon />,
							to: "/membership/member/distributors",
						},
						{
							title: "Reseller Gold",
							icon: <GroupAddIcon />,
							to: "/membership/member/reseller-gold",
						},
						{
							title: "Agen",
							icon: <GroupsIcon />,
							to: "/membership/member/agents",
						},
						{
							title: "Member Premium",
							icon: <GroupAddIcon />,
							to: "/membership/member/premium",
						},
						{
							title: "Distributor Plus",
							icon: <GroupAddIcon />,
							to: "/membership/member/ditributor-plus",
						},
					],
				},
				{
					title: "User Admin",
					isCollapse: false,
					icon: <PersonIcon />,
					to: "/membership/user-admin",
				},
				{
					title: "Broadcast",
					isCollapse: true,
					icon: <CampaignIcon />,
					onclick: () => setOpenBroadcast(!openBroadcast),
					open: openBroadcast,
					subitems: [
						{
							title: "Send Email",
							icon: <EmailIcon />,
							to: "/broadcast/send-email",
						},
						{
							title: "Push Notif",
							icon: <NotificationsActiveIcon />,
							to: "/broadcast/push-notif",
						},
						{
							title: "Whatsapp",
							icon: <WhatsAppIcon />,
							to: "/broadcast/whatsapp",
						},
					],
				},
			],
		},
		{
			title: "Pengaturan",
			items: [
				{
					title: "Pengaturan Lainnya",
					isCollapse: false,
					icon: <SettingsIcon />,
					to: "/settings/others",
				},
				{
					title: "Daerah",
					isCollapse: true,
					icon: <PlaceIcon />,
					onclick: () => setOpenArea(!openArea),
					open: openArea,
					subitems: [
						{
							title: "Provinsi",
							icon: <PlaceIcon />,
							to: "/settings/provinces",
						},
						{
							title: "Kabupaten/Kota",
							icon: <PlaceIcon />,
							to: "/settings/cities",
						},
						{
							title: "Kecamatan",
							icon: <PlaceIcon />,
							to: "/settings/subdistricts",
						},
					],
				},
			],
		},
	];

	const sidebarMenu = isSuperAdmin() ? temp_menus : distributor_menus;

	return (
		<>
			<MenuWrapper>
				{/* Start */}
				{sidebarMenu.map((menu, index) => (
					<List
						key={"content" + index}
						component="div"
						subheader={
							<ListSubheader component="div" disableSticky>
								{menu.title}
							</ListSubheader>
						}>
						{menu.items.map((item, index) => (
							<SubMenuWrapper key={"item" + index}>
								<List component="div">
									{item.isCollapse ? (
										<>
											<ListItem component="div">
												<Button
													disableRipple
													onClick={item.onclick}
													startIcon={item.icon}
													endIcon={
														item.open ? <ExpandLessIcon /> : <ExpandMoreIcon />
													}>
													{item.title}
												</Button>
											</ListItem>
											<Collapse in={item.open} timeout="auto" unmountOnExit>
												<List component="div" disablePadding>
													{item.subitems.map((item) => (
														<ListItem key={item.title}>
															<Button
																disableRipple
																onClick={closeSidebar}
																component={RouterLink}
																to={item.to}
																startIcon={item.icon}>
																{item.title}
															</Button>
														</ListItem>
													))}
												</List>
											</Collapse>
										</>
									) : (
										<>
											<ListItem component="div">
												<Button
													disableRipple
													component={RouterLink}
													to={item.to}
													startIcon={item.icon}>
													{item.title}
												</Button>
											</ListItem>
										</>
									)}
								</List>
							</SubMenuWrapper>
						))}
					</List>
				))}
				{/* End */}

				{false && (
					<>
						<List component="div">
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/overview"
											startIcon={<DesignServicesTwoToneIcon />}>
											Overview
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Dashboards
								</ListSubheader>
							}>
							<ListItem button onClick={handleToggle}>
								<Button
									disableRipple
									startIcon={<BrightnessLowTwoToneIcon />}
									endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
									Cryptocurrency
								</Button>
							</ListItem>
							<Collapse in={open} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									{crypto_items.map((item) => (
										<ListItem
											key={item.title}
											component={RouterLink}
											to={item.to}>
											<Button disableRipple startIcon={item.icon}>
												{item.title}
											</Button>
										</ListItem>
									))}
								</List>
							</Collapse>
							<ListItem
								button
								component={RouterLink}
								onClick={closeSidebar}
								to="/dashboards/messenger">
								<Button disableRipple startIcon={<MmsTwoToneIcon />}>
									Messenger
								</Button>
							</ListItem>
						</List>

						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Dashboards
								</ListSubheader>
							}>
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/dashboards/crypto"
											startIcon={<BrightnessLowTwoToneIcon />}>
											Cryptocurrency
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/dashboards/messenger"
											startIcon={<MmsTwoToneIcon />}>
											Messenger
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Management
								</ListSubheader>
							}>
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar1}
											to="/management/transactions"
											startIcon={<TableChartTwoToneIcon />}>
											Transactions List
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Accounts
								</ListSubheader>
							}>
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/management/profile/details"
											startIcon={<AccountCircleTwoToneIcon />}>
											User Profile
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/management/profile/settings"
											startIcon={<DisplaySettingsTwoToneIcon />}>
											Account Settings
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Components
								</ListSubheader>
							}>
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/buttons"
											startIcon={<BallotTwoToneIcon />}>
											Buttons
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/modals"
											startIcon={<BeachAccessTwoToneIcon />}>
											Modals
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/accordions"
											startIcon={<EmojiEventsTwoToneIcon />}>
											Accordions
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/tabs"
											startIcon={<FilterVintageTwoToneIcon />}>
											Tabs
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/badges"
											startIcon={<HowToVoteTwoToneIcon />}>
											Badges
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/tooltips"
											startIcon={<LocalPharmacyTwoToneIcon />}>
											Tooltips
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/avatars"
											startIcon={<RedeemTwoToneIcon />}>
											Avatars
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/cards"
											startIcon={<SettingsTwoToneIcon />}>
											Cards
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/components/forms"
											startIcon={<TrafficTwoToneIcon />}>
											Forms
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
						<List
							component="div"
							subheader={
								<ListSubheader component="div" disableSticky>
									Extra Pages
								</ListSubheader>
							}>
							<SubMenuWrapper>
								<List component="div">
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/status/404"
											startIcon={<CheckBoxTwoToneIcon />}>
											Error 404
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/status/500"
											startIcon={<CameraFrontTwoToneIcon />}>
											Error 500
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/status/coming-soon"
											startIcon={<ChromeReaderModeTwoToneIcon />}>
											Coming Soon
										</Button>
									</ListItem>
									<ListItem component="div">
										<Button
											disableRipple
											component={RouterLink}
											onClick={closeSidebar}
											to="/status/maintenance"
											startIcon={<WorkspacePremiumTwoToneIcon />}>
											Maintenance
										</Button>
									</ListItem>
								</List>
							</SubMenuWrapper>
						</List>
					</>
				)}
			</MenuWrapper>
		</>
	);
}

export default SidebarMenu;
