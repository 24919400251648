import { axiosInstance } from "./axiosInstance";
import apiUrl from "./apiUrl";

export const AuthService = {
    login: async (data: any) => {
        return await axiosInstance.post(`${apiUrl}/login`, data);
    },
    register: async (data: any) => {
        return await axiosInstance.post(`${apiUrl}/register`, data);
    },
}

export const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('USER') || '{}');
}

export const isLoggedIn = () => {
    return localStorage.getItem('TOKEN') !== null ? true : false;
}