import axios from 'axios';
import swal from 'sweetalert';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://naisha-backend.test',
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('TOKEN');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    }
);
const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    FORBIDDEN: 403,
    BAD_REQUEST: 400,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
};

const redirectToLogin = () => {
    localStorage.removeItem('TOKEN');
    localStorage.removeItem('USER');
    window.location.href = "/login";
};

const showAlert = (title, text, statusCode, redirectAfter = false) => {
    swal({ title, text, icon: "error" }).then(() => {
        if (redirectAfter) {
            if (statusCode === HTTP_STATUS.UNAUTHORIZED) {
                redirectToLogin();
            }
        }
    });
};

const handleErrorResponse = (error) => {
    const status = error.response?.status;

    switch (status) {
        case HTTP_STATUS.UNAUTHORIZED:
            if (window.location.pathname !== "/login") {
                showAlert(
                    "Unauthorized",
                    "Sesi berakhir, silahkan login kembali",
                    HTTP_STATUS.UNAUTHORIZED,
                    true
                );
            }
            break;
        case HTTP_STATUS.NOT_FOUND:
            showAlert(
                "Not Found",
                "Halaman tidak ditemukan",
                HTTP_STATUS.NOT_FOUND
            );
            break;
        case HTTP_STATUS.INTERNAL_SERVER_ERROR:
            showAlert(
                "Internal Server Error",
                "Terjadi kesalahan pada server",
                HTTP_STATUS.INTERNAL_SERVER_ERROR,
                true
            );
            break;
        case HTTP_STATUS.FORBIDDEN:
            showAlert(
                "Forbidden",
                "Anda tidak memiliki akses ke halaman ini",
                HTTP_STATUS.FORBIDDEN,
                true
            );
            break;
        case HTTP_STATUS.BAD_REQUEST:
            showAlert(
                "Bad Request",
                error.response?.data?.message ?? "Request tidak valid",
                HTTP_STATUS.BAD_REQUEST
            );
            break;
        case HTTP_STATUS.METHOD_NOT_ALLOWED:
            showAlert(
                "Method Not Allowed",
                "Method tidak diizinkan",
                HTTP_STATUS.METHOD_NOT_ALLOWED,
                true
            );
            break;
        case HTTP_STATUS.CONFLICT:
            showAlert(
                "Conflict",
                "Data sudah ada",
                HTTP_STATUS.CONFLICT,
                true
            );
            break;
        default:
            showAlert(
                "Error",
                "Terjadi kesalahan",
                null,
                false
            );
            break;
    }

    return Promise.reject(error);
};


axiosInstance.interceptors.response.use(
    (response) => response,
    handleErrorResponse
);