import { Suspense, lazy, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import { isLoggedIn } from "src/services/auth.service";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";

const Loader = (Component) => (props) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

// Pages

// Dashboards
const Dashboard = Loader(lazy(() => import("src/content/dashboards")));

// Status

const Status404 = Loader(
	lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
	lazy(() => import("src/content/pages/Status/Status500"))
);
const StatusComingSoon = Loader(
	lazy(() => import("src/content/pages/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
	lazy(() => import("src/content/pages/Status/Maintenance"))
);

// auth
const Login = Loader(lazy(() => import("src/content/pages/Auth/Login")));
const Register = Loader(lazy(() => import("src/content/pages/Auth/Register")));

// analytics
const Sale = Loader(lazy(() => import("src/content/analytics/Sale")));
const Statistics = Loader(lazy(() => import("src/content/analytics/Sales")));
const Report = Loader(lazy(() => import("src/content/analytics/Report")));
const BestProducts = Loader(
	lazy(() => import("src/content/analytics/BestProducts"))
);
const BestMembers = Loader(
	lazy(() => import("src/content/analytics/BestMembers"))
);
const BestSellers = Loader(
	lazy(() => import("src/content/analytics/BestSellers"))
);
const BestCustomers = Loader(
	lazy(() => import("src/content/analytics/BestCustomers"))
);
const BestLocations = Loader(
	lazy(() => import("src/content/analytics/BestLocations"))
);
const BestPIC = Loader(lazy(() => import("src/content/analytics/BestPICs")));
const VoucherUsed = Loader(
	lazy(() => import("src/content/analytics/VoucherUsed"))
);
const Affiliate = Loader(
	lazy(() => import("src/content/dashboards/Affiliate"))
);
const EditBalance = Loader(
	lazy(() => import("src/content/dashboards/Affiliate/EditBalance"))
);
const UserFeedback = Loader(
	lazy(() => import("src/content/dashboards/UserFeedback"))
);
const Moota = Loader(lazy(() => import("src/content/dashboards/Moota")));

// store
const Orders = Loader(lazy(() => import("src/content/pages/Store/Orders")));
const OrderComplains = Loader(lazy(() => import("src/content/pages/Store/OrderComplains")));
const NewOrder = Loader(lazy(() => import("src/content/pages/Store/Order/NewOrder")));
const PrintLabel = Loader(
	lazy(() => import("src/content/pages/Store/PrintLabel"))
);
const Products = Loader(
	lazy(() => import("src/content/pages/Store/Products/Products"))
);
const ProductsAdd = Loader(
	lazy(
		() => import("src/content/pages/Store/Products/Products/AddProductAdmin1")
	)
);
const ProductsEdit = Loader(
	lazy(
		() => import("src/content/pages/Store/Products/Products/AddProductAdmin1")
	)
);
const Category = Loader(
	lazy(() => import("src/content/pages/Store/Products/Category"))
);
const SubCategory = Loader(
	lazy(() => import("src/content/pages/Store/Products/SubCategory"))
);
const Wishlist = Loader(
	lazy(() => import("src/content/pages/Store/Products/Wishlist"))
);
const Hubungan = Loader(
	lazy(() => import("src/content/pages/Store/Products/Hubungan"))
);
const PIC = Loader(lazy(() => import("src/content/pages/Store/PIC")));
const AddPIC = Loader(lazy(() => import("src/content/pages/Store/PIC/AddPIC")));
const FAQ = Loader(lazy(() => import("src/content/pages/Store/FAQ")));
const AddFAQ = Loader(lazy(() => import("src/content/pages/Store/FAQ/AddFAQ")));
const Banner = Loader(
	lazy(() => import("src/content/pages/Store/Settings/Banner"))
);
const NewBanner = Loader(
	lazy(() => import("src/content/pages/Store/Settings/Banner/NewBanner"))
);
const Popup = Loader(
	lazy(() => import("src/content/pages/Store/Settings/Popup"))
);
const NewPopup = Loader(
	lazy(() => import("src/content/pages/Store/Settings/Popup/NewPopup"))
);
const CsCategory = Loader(
	lazy(() => import("src/content/pages/Store/Settings/CsCategory"))
);
const NewCsCategory = Loader(
	lazy(
		() => import("src/content/pages/Store/Settings/CsCategory/NewCsCategory")
	)
);
const CustomerServices = Loader(
	lazy(() => import("src/content/pages/Store/Settings/CustomerServices"))
);
const NewCustomerService = Loader(
	lazy(
		() =>
			import(
				"src/content/pages/Store/Settings/CustomerServices/NewCustomerService"
			)
	)
);
const BankAccount = Loader(
	lazy(() => import("src/content/pages/Store/Settings/BankAccount"))
);
const NewBankAccount = Loader(
	lazy(
		() => import("src/content/pages/Store/Settings/BankAccount/NewBankAccount")
	)
);
const Couriers = Loader(
	lazy(() => import("src/content/pages/Store/Settings/Couriers"))
);
const NewCourier = Loader(
	lazy(() => import("src/content/pages/Store/Settings/Couriers/NewCourier"))
);
const UserCod = Loader(
	lazy(() => import("src/content/pages/Store/Settings/UserCod"))
);
const NewUserCod = Loader(
	lazy(() => import("src/content/pages/Store/Settings/UserCod/NewUserCod"))
);

const Discount = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Discounts"))
);
const NewDiscount = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Discounts/NewDiscount"))
);
const Coupon = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Coupon"))
);

const Flashsale = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Flashsale"))
);
const NewFlashsale = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Flashsale/NewFlashsale"))
);
const Grosir = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Grosir"))
);
const NewGrosir = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Grosir/NewGrosir"))
);
const Bundling = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Bundling"))
);
const AddBundling = Loader(
	lazy(() => import("src/content/pages/Store/Discounts/Bundling/NewBundling1"))
);

const Rewards = Loader(lazy(() => import("src/content/pages/Store/Rewards")));
const NewReward = Loader(
	lazy(() => import("src/content/pages/Store/Rewards/NewReward"))
);

// features
const ActivityRepetitions = Loader(
	lazy(() => import("src/content/pages/Features/Activity/Repetitions"))
);
const AddActivityRepeat = Loader(
	lazy(
		() =>
			import(
				"src/content/pages/Features/Activity/Repetitions/NewActivityRepetition"
			)
	)
);
const ActivityCategory = Loader(
	lazy(() => import("src/content/pages/Features/Activity/Category"))
);
const AddActivityCategory = Loader(
	lazy(
		() =>
			import("src/content/pages/Features/Activity/Category/NewActivityCategory")
	)
);

const ActivityUnit = Loader(
	lazy(() => import("src/content/pages/Features/Activity/Unit"))
);
const AddActivityUnit = Loader(
	lazy(() => import("src/content/pages/Features/Activity/Unit/NewUnit"))
);
const Activity = Loader(
	lazy(() => import("src/content/pages/Features/Activity/Activity"))
);
const AddActivity = Loader(
	lazy(() => import("src/content/pages/Features/Activity/Activity/NewActivity"))
);
const JournalPackage = Loader(
	lazy(() => import("src/content/pages/Features/Journal/Package"))
);
const AddJournalPackage = Loader(
	lazy(
		() => import("src/content/pages/Features/Journal/Package/NewJournalPackage")
	)
);

const Journal = Loader(
	lazy(() => import("src/content/pages/Features/Journal/Journal"))
);
const AddJournal = Loader(
	lazy(() => import("src/content/pages/Features/Journal/Journal/NewJournal"))
);
const Notes = Loader(
	lazy(() => import("src/content/pages/Features/Journal/Notes"))
);
const AddNote = Loader(
	lazy(() => import("src/content/pages/Features/Journal/Notes/NewNote"))
);
const ArticleCategory = Loader(
	lazy(() => import("src/content/pages/Features/Article/Category"))
);
const AddArticleCategory = Loader(
	lazy(
		() =>
			import("src/content/pages/Features/Article/Category/NewArticleCategory")
	)
);
const ArticleList = Loader(
	lazy(() => import("src/content/pages/Features/Article/List"))
);
const AddArticle = Loader(
	lazy(() => import("src/content/pages/Features/Article/List/NewArticle"))
);
const Post = Loader(lazy(() => import("src/content/pages/Features/Post/Post")));
const AddPost = Loader(
	lazy(() => import("src/content/pages/Features/Post/Post/NewPost"))
);
const PostReport = Loader(
	lazy(() => import("src/content/pages/Features/Post/Report"))
);
const AddPostReport = Loader(
	lazy(() => import("src/content/pages/Features/Post/Report/NewPostReport"))
);
const PostComment = Loader(
	lazy(() => import("src/content/pages/Features/Post/Comment"))
);
const AddPostComment = Loader(
	lazy(() => import("src/content/pages/Features/Post/Comment/NewPostComment"))
);
const PostCommentReport = Loader(
	lazy(() => import("src/content/pages/Features/Post/CommentReport"))
);
const AddPostCommentReport = Loader(
	lazy(
		() =>
			import(
				"src/content/pages/Features/Post/CommentReport/NewPostCommentReport"
			)
	)
);
const Period = Loader(
	lazy(() => import("src/content/pages/Features/Period/Period"))
);
const AddPeriod = Loader(
	lazy(() => import("src/content/pages/Features/Period/Period/NewPeriod"))
);
const PeriodSetting = Loader(
	lazy(() => import("src/content/pages/Features/Period/Setting"))
);
const AddPeriodSetting = Loader(
	lazy(
		() => import("src/content/pages/Features/Period/Setting/NewPeriodSetting")
	)
);
const Prayer = Loader(
	lazy(() => import("src/content/pages/Features/Prayer/Prayer"))
);
const PrayerCategory = Loader(
	lazy(() => import("src/content/pages/Features/Prayer/Category"))
);
const AsmaulHusna = Loader(
	lazy(() => import("src/content/pages/Features/AsmaulHusna"))
);
const AddAsmaulHusna = Loader(
	lazy(() => import("src/content/pages/Features/AsmaulHusna/AddAsmaulHusna"))
);
const ShareVideo = Loader(
	lazy(() => import("src/content/pages/Features/Video"))
);
const AddShareVideo = Loader(
	lazy(() => import("src/content/pages/Features/Video/NewVideo"))
);
const QuranJuz = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Juz"))
);
const AddQuranJuz = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Juz/NewJuz"))
);
const QuranSurah = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Surah"))
);
const AddQuranSurah = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Surah/NewSurah"))
);

const QuranVerse = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Verses"))
);
const AddQuranVerses = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Verses/NewVerse"))
);
const QuranLastRead = Loader(
	lazy(() => import("src/content/pages/Features/Quran/LastRead"))
);
const AddQuranLastRead = Loader(
	lazy(() => import("src/content/pages/Features/Quran/LastRead/NewLastRead"))
);

const QuranFavorite = Loader(
	lazy(() => import("src/content/pages/Features/Quran/Favorite"))
);
const AddQuranFavorite = Loader(
	lazy(
		() => import("src/content/pages/Features/Quran/Favorite/NewVerseFavorite")
	)
);

// membership
const MemberAll = Loader(
	lazy(() => import("src/content/pages/Membership/Member/All"))
);

const MemberDeleted = Loader(
	lazy(() => import("src/content/pages/Membership/Member/Deleted"))
);

const UserAdmin = Loader(
	lazy(() => import("src/content/pages/Membership/UserAdmin"))
);

const SendEmail = Loader(
	lazy(() => import("src/content/pages/Membership/SendEmail"))
);

const PushNotification = Loader(
	lazy(() => import("src/content/pages/Membership/PushNotification"))
);

const WhatsApp = Loader(
	lazy(() => import("src/content/pages/Membership/WhatsApp"))
);

// settings
const Provinces = Loader(
	lazy(() => import("src/content/pages/Setting/Area/Province"))
);
const Cities = Loader(
	lazy(() => import("src/content/pages/Setting/Area/City"))
);
const SubDistrict = Loader(
	lazy(() => import("src/content/pages/Setting/Area/SubDistrict"))
);

const Test = Loader(lazy(() => import("src/content/pages/Test")));

type RouterProps = {
	auth: any;
};

// export default function router({ auth }: RouterProps) {
const routes: RouteObject[] = [
	{
		path: "",
		element: <BaseLayout />,
		children: [
			{
				path: "/",
				element: <Navigate to="/dashboards" replace />,
			},
			{
				path: "overview",
				element: <Navigate to="/" replace />,
			},
			{
				path: "status",
				children: [
					{
						path: "",
						element: <Navigate to="404" replace />,
					},
					{
						path: "404",
						element: <Status404 />,
					},
					{
						path: "500",
						element: <Status500 />,
					},
					{
						path: "maintenance",
						element: <StatusMaintenance />,
					},
					{
						path: "coming-soon",
						element: <StatusComingSoon />,
					},
				],
			},
			{
				path: "*",
				element: <Status404 />,
			},
		],
	},
	{
		path: "test",
		element: <SidebarLayout />,
		children: [
			{
				path: "",
				element: <Test />,
			},
		],
	},
	{
		path: "dashboards",
		element: <SidebarLayout />,
		children: isLoggedIn()
			? [
					{
						path: "",
						element: <Dashboard />,
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},
	{
		path: "analytics",
		element: <SidebarLayout />,
		children: isLoggedIn()
			? [
					{
						path: "report",
						element: <Report />,
					},
					{
						path: "sale",
						element: <Sale />,
					},
					{
						path: "statistics",
						element: <Statistics />,
					},
					{
						path: "best-products",
						element: <BestProducts />,
					},
					{
						path: "best-members",
						element: <BestMembers />,
					},
					{
						path: "best-customers",
						element: <BestCustomers />,
					},
					{
						path: "best-sellers",
						element: <BestSellers />,
					},
					{
						path: "best-locations",
						element: <BestLocations />,
					},
					{
						path: "best-pic",
						element: <BestPIC />,
					},
					{
						path: "voucher-used",
						element: <VoucherUsed />,
					},
					{
						path: "affiliate",
						children: [
							{ path: "", element: <Affiliate /> },
							{ path: "edit-balance/:id", element: <EditBalance /> },
						],
					},
					{
						path: "user-feedback",
						element: <UserFeedback />,
					},
					{
						path: "moota",
						element: <Moota />,
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},
	{
		path: "features",
		element: <SidebarLayout />,
		children: isLoggedIn
			? [
					{
						path: "activity-repetition",
						children: [
							{ path: "", element: <ActivityRepetitions /> },
							{ path: "add", element: <AddActivityRepeat /> },
							{ path: "edit/:id", element: <AddActivityRepeat /> },
						],
					},
					{
						path: "activity-categories",
						children: [
							{ path: "", element: <ActivityCategory /> },
							{ path: "add", element: <AddActivityCategory /> },
							{ path: "edit/:id", element: <AddActivityCategory /> },
						],
					},
					{
						path: "activity-unit",
						children: [
							{ path: "", element: <ActivityUnit /> },
							{ path: "add", element: <AddActivityUnit /> },
							{ path: "edit/:id", element: <AddActivityUnit /> },
						],
					},
					{
						path: "activity",
						children: [
							{ path: "", element: <Activity /> },
							{ path: "add", element: <AddActivity /> },
							{ path: "edit/:id", element: <AddActivity /> },
						],
					},
					{
						path: "journal-packages",
						children: [
							{ path: "", element: <JournalPackage /> },
							{ path: "add", element: <AddJournalPackage /> },
							{ path: "edit/:id", element: <AddJournalPackage /> },
						],
					},
					{
						path: "journal",
						children: [
							{ path: "", element: <Journal /> },
							{ path: "add", element: <AddJournal /> },
							{ path: "edit/:id", element: <AddJournal /> },
						],
					},
					{
						path: "journal-notes",
						children: [
							{ path: "", element: <Notes /> },
							{ path: "add", element: <AddNote /> },
							{ path: "edit/:id", element: <AddNote /> },
						],
					},
					{
						path: "article-categories",
						children: [
							{ path: "", element: <ArticleCategory /> },
							{ path: "add", element: <AddArticleCategory /> },
							{ path: "edit/:id", element: <AddArticleCategory /> },
						],
					},
					{
						path: "article-list",
						children: [
							{ path: "", element: <ArticleList /> },
							{ path: "add", element: <AddArticle /> },
							{ path: "edit/:id", element: <AddArticle /> },
						],
					},
					{
						path: "posts",
						children: [
							{ path: "", element: <Post /> },
							{ path: "add", element: <AddPost /> },
							{ path: "edit/:id", element: <AddPost /> },
						],
					},
					{
						path: "post-reports",
						children: [
							{ path: "", element: <PostReport /> },
							{ path: "add", element: <AddPostReport /> },
							{ path: "edit/:id", element: <AddPostReport /> },
						],
					},
					{
						path: "post-comments",
						children: [
							{ path: "", element: <PostComment /> },
							{ path: "add", element: <AddPostComment /> },
							{ path: "edit/:id", element: <AddPostComment /> },
						],
					},
					{
						path: "post-comments-reports",
						children: [
							{ path: "", element: <PostCommentReport /> },
							{ path: "add", element: <AddPostCommentReport /> },
							{ path: "edit/:id", element: <AddPostCommentReport /> },
						],
					},
					{
						path: "periods",
						children: [
							{ path: "", element: <Period /> },
							{ path: "add", element: <AddPeriod /> },
							{ path: "edit/:id", element: <AddPeriod /> },
						],
					},
					{
						path: "periods-settings",
						children: [
							{ path: "", element: <PeriodSetting /> },
							{ path: "add", element: <AddPeriodSetting /> },
							{ path: "edit/:id", element: <AddPeriodSetting /> },
						],
					},
					{
						path: "prayers",
						element: <Prayer />,
					},
					{
						path: "prayers-categories",
						element: <PrayerCategory />,
					},
					{
						path: "asmaul-husna",
						children: [
							{ path: "", element: <AsmaulHusna /> },
							{ path: "add", element: <AddAsmaulHusna /> },
							{ path: "edit/:id", element: <AddAsmaulHusna /> },
						],
					},
					{
						path: "share-videos",
						children: [
							{ path: "", element: <ShareVideo /> },
							{ path: "add", element: <AddShareVideo /> },
							{ path: "edit/:id", element: <AddShareVideo /> },
						],
					},
					{
						path: "quran-juz",
						children: [
							{ path: "", element: <QuranJuz /> },
							{ path: "add", element: <AddQuranJuz /> },
							{ path: "edit/:id", element: <AddQuranJuz /> },
						],
					},
					{
						path: "quran-surah",
						children: [
							{ path: "", element: <QuranSurah /> },
							{ path: "add", element: <AddQuranSurah /> },
							{ path: "edit/:id", element: <AddQuranSurah /> },
						],
					},
					{
						path: "quran-verses",
						children: [
							{ path: "", element: <QuranVerse /> },
							{ path: "add", element: <AddQuranVerses /> },
							{ path: "edit/:id", element: <AddQuranVerses /> },
						],
					},
					{
						path: "quran-last-read",
						children: [
							{ path: "", element: <QuranLastRead /> },
							{ path: "add", element: <AddQuranLastRead /> },
							{ path: "edit/:id", element: <AddQuranLastRead /> },
						],
					},
					{
						path: "quran-favorite",
						children: [
							{ path: "", element: <QuranFavorite /> },
							{ path: "add", element: <AddQuranFavorite /> },
							{ path: "edit/:id", element: <AddQuranFavorite /> },
						],
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},
	{
		path: "store",
		element: <SidebarLayout />,
		children: isLoggedIn
			? [
					{
						path: "orders",
						children: [{ path: "", element: <Orders /> }],
					},
					{
						path: "order-complains",
						children: [{ path: "", element: <OrderComplains /> }],
					},
					{
						path: "new-order",
						element: <NewOrder />,
					},
					{
						path: "edit-order/:id",
						element: <NewOrder />,
					},
					{
						path: "print-label",
						element: <PrintLabel />,
					},
					{
						path: "products",
						element: <Products />,
					},
					{
						path: "products/add",
						element: <ProductsAdd />,
					},
					{
						path: "products/edit/:name",
						element: <ProductsEdit />,
					},
					{
						path: "category",
						element: <Category />,
					},
					{
						path: "sub-category",
						element: <SubCategory />,
					},
					{
						path: "wishlist",
						element: <Wishlist />,
					},
					{
						path: "connection",
						element: <Hubungan />,
					},
					{
						path: "PIC",
						children: [
							{ path: "", element: <PIC /> },
							{ path: "add", element: <AddPIC /> },
							{ path: "edit/:id", element: <AddPIC /> },
						],
					},
					{
						path: "faq",
						children: [
							{ path: "", element: <FAQ /> },
							{ path: "add", element: <AddFAQ /> },
							{ path: "edit/:id", element: <AddFAQ /> },
						],
					},
					{
						path: "rewards",
						children: [
							{ path: "", element: <Rewards /> },
							{ path: "add", element: <NewReward /> },
							{ path: "edit/:id", element: <NewReward /> },
						],
					},
					{
						path: "discount",
						element: <Discount />,
					},
					{
						path: "discount/add",
						element: <NewDiscount />,
					},
					{
						path: "discount/edit/:id",
						element: <NewDiscount />,
					},
					{
						path: "coupon",
						element: <Coupon />,
					},
					{
						path: "flashsale",
						element: <Flashsale />,
					},
					{
						path: "flashsale/add",
						element: <NewFlashsale />,
					},
					{
						path: "flashsale/edit/:id",
						element: <NewFlashsale />,
					},
					{
						path: "grosir",
						element: <Grosir />,
					},
					{
						path: "grosir/add",
						element: <NewGrosir />,
					},
					{
						path: "grosir/edit/:id",
						element: <NewGrosir/>,
					},
					{
						path: "bundling",
						element: <Bundling />,
					},
					{
						path: "bundling/add",
						element: <AddBundling />,
					},
					{
						path: "bundling/edit/:id",
						element: <AddBundling />,
					},
					{
						path: "settings/banner",
						children: [
							{ path: "", element: <Banner /> },
							{ path: "add", element: <NewBanner /> },
							{ path: "edit/:id", element: <NewBanner /> },
						],
					},
					{
						path: "settings/popup",
						children: [
							{ path: "", element: <Popup /> },
							{ path: "add", element: <NewPopup /> },
							{ path: "edit/:id", element: <NewPopup /> },
						],
					},
					{
						path: "settings/cs-category",
						children: [
							{ path: "", element: <CsCategory /> },
							{ path: "add", element: <NewCsCategory /> },
							{ path: "edit/:id", element: <NewCsCategory /> },
						],
					},
					{
						path: "settings/customer-service",
						children: [
							{ path: "", element: <CustomerServices /> },
							{ path: "add", element: <NewCustomerService /> },
							{ path: "edit/:id", element: <NewCustomerService /> },
						],
					},
					{
						path: "settings/bank-account",
						children: [
							{ path: "", element: <BankAccount /> },
							{ path: "add", element: <NewBankAccount /> },
							{ path: "edit/:id", element: <NewBankAccount /> },
						],
					},
					{
						path: "settings/courier",
						children: [
							{ path: "", element: <Couriers /> },
							{ path: "add", element: <NewCourier /> },
							{ path: "edit/:id", element: <NewCourier /> },
						],
					},
					{
						path: "settings/user-cod",
						children: [
							{ path: "", element: <UserCod /> },
							{ path: "add", element: <NewUserCod /> },
							{ path: "edit/:id", element: <NewUserCod /> },
						],
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},
	{
		path: "membership",
		element: <SidebarLayout />,
		children: isLoggedIn
			? [
					{
						path: "member/all",
						element: <MemberAll />,
					},
					{
						path: "member/customers",
						element: <MemberAll />,
					},
					{
						path: "member/reseller",
						element: <MemberAll />,
					},
					{
						path: "member/distributors",
						element: <MemberAll />,
					},
					{
						path: "member/reseller-gold",
						element: <MemberAll />,
					},
					{
						path: "member/agents",
						element: <MemberAll />,
					},
					{
						path: "member/premium",
						element: <MemberAll />,
					},
					{
						path: "member/ditributor-plus",
						element: <MemberAll />,
					},
					{
						path: "user-admin",
						element: <UserAdmin />,
					},
					{
						path: "member/deleted",
						element: <MemberDeleted />,
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},
	{
		path: "broadcast",
		element: <SidebarLayout />,
		children: isLoggedIn
			? [
					{
						path: "whatsapp",
						element: <WhatsApp />,
					},
					{
						path: "send-email",
						element: <SendEmail />,
					},
					{
						path: "push-notif",
						element: <PushNotification />,
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},
	{
		path: "settings",
		element: <SidebarLayout />,
		children: isLoggedIn
			? [
					{
						path: "provinces",
						element: <Provinces />,
					},
					{
						path: "cities",
						element: <Cities />,
					},
					{
						path: "subdistricts",
						element: <SubDistrict />,
					},
			  ]
			: [
					{
						path: "",
						element: <Navigate to="/login" replace />,
					},
			  ],
	},

	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/register",
		element: <Register />,
	},
];

export default routes;
