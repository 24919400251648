
const UserRoleUtils = () => {
    return process.env.REACT_APP_USER_ROLE || 'super_admin' ;
}

export const isSuperAdmin = () => {
    return UserRoleUtils() === 'super_admin';
}

export const isDistributor = () => {
    return UserRoleUtils() === 'distributor';
}