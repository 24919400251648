import { useContext } from 'react';

import { Box, IconButton, Tooltip, useTheme } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';


function Toggle() {
    const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
    const theme = useTheme();
    
    return (
        <Box
        component="span"
        sx={{
            m: 2,
            display: { lg: 'none', xs: 'inline-block' }
        }}
        >
        <Tooltip arrow title="Lihat Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
            {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
            ) : (
                <CloseTwoToneIcon fontSize="small" />
            )}
            </IconButton>
        </Tooltip>
        </Box>
    );
}

export default Toggle;